@import '../../Reusables.scss';

.select-button{
    display: flex;
    flex-wrap: wrap;
    font-family: "Gilroy", sans-serif;
    @include mobile{
        width: 100%;
    }
    &.light{
        .select-country{
            border: 1px solid #FFFFFF;
            background: rgba(255, 255, 255, 0.1);
            color: #FFFFFF;
            span{
                color: #FFFFFF;
            }
            p{
                color: #FFFFFF;
            }
        }
        button{
            background: #FFFFFF;
            color: #1627B6;
        }
    }
    &.dark{
        .select-country{
            border: 1px solid #C0C0C0;
            background: rgba(255, 255, 255, 0.1);
            span{
                color: #565656;
            }
            p{
                color: #161616;
            }
            .arrow{
                color:  #565656;
            }
        }
        button{
            background: #004AD9;
            color: #FFFFFF;
        }
    }
    .select-country{
        min-width: 217px;
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 18px;
        position: relative;
        margin-right: 12px;
        margin-bottom: 20px;
        @include mobile{
            margin-right: 0;
            width: 100%;
        }
        &.alert{
            border: 1px solid #734A2A
        }
        .label{
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 12px;
        // width: 80%;
        margin-bottom: 5px;
            @include mobile{
                font-size: 10px;
            }
        }
        .flag-country{
            display: flex;
            align-items: center;
            cursor: pointer;
            img{
                margin-right: 8px;
                width: 21.83px;
                height: 11.95px;
            }
            p{
                font-size: 16px;
                line-height: 16px;
                font-family: "Gilroy";
            }
        }
        .arrow{
            width: 12px;
            height: auto;
            cursor: pointer;
            @include mobile{
                width: 10px;
            }
        }
    }
    button{
        width: 217px;
        height: 64px;
        border: none;
        cursor: pointer;
        font-weight: bold;
        font-size: 16px;
        &:focus, &:active{
            outline: none;
        }
    }
    @include mobile{
        a{
            width: 100%;
            button{
                width: 100%;
            }
        }
    }
}
.select-country{
    min-width: 217px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 18px;
    position: relative;
    margin-bottom: 20px;
    border: 1px solid #C0C0C0;
    @include mobile{
        margin-right: 0;
        width: 100%;
        padding: 0 12px;
    }
    &.alert{
        border: 1px solid #734A2A
    }
    &.locked{
        background: rgba(13, 11, 11, 0.1);
        .flag-country{
            cursor: initial;
        }
    }
    .label{
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 12px;
        margin-bottom: 5px;
        @include mobile{
            font-size: 10px;
        }
    }
    .flag-country{
        display: flex;
        align-items: center;
        cursor: pointer;
        text-transform: capitalize;
        img{
            margin-right: 8px;
            width: 21.83px;
            height: 11.95px;
        }
        p{
            font-size: 16px;
            line-height: 16px;
        }
    }
    .arrow{
        width: 12px;
        height: auto;
        cursor: pointer;
        @include mobile{
            width: 10px;
        }
    }
}
.discover-btn{
    width: 100%;
    height: 64px;
    margin: 16px 0 10px;
    border: none;
    cursor: pointer;
    font-weight: bold;
    font-size: 16px;
    background: #004AD9;
    color: #FFFFFF;
    &:focus, &:active{
        outline: none;
    }
}