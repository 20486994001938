@import '../../Reusables.scss';

.countries-list{
    transition: 0.1s ease-in;
    &.hide{
        max-height: 0;
        overflow: hidden;
        padding: 0;
        opacity: 0;
    }
    &.show{
        max-height: 100vh;
        opacity: 1;
    }
    background: rgba(255,255,255,0.98);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    z-index: 20;
    padding: 30px 10%;
    overscroll-behavior: contain;
    @include mobile{
        padding: 14px 4%; 
    }
    .top-close{
        display: flex;
        justify-content: flex-end;
        .close{
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: uppercase;
            cursor: pointer;
            svg{
                height: 16px;
                width: 16px;
                fill: #000000;
            }
            p{
                font-size: 18px;
                line-height: 150%;
                margin-left: 10px;
                color: #2C2C2C;
            }
            @include mobile{
                width: 112px;
                height: 39px;
                background: #D33715;
                svg{
                    height: 14px;
                    width: 14px;
                    fill: #FFFFFF;
                }
                p{
                    font-size: 14px;
                    color: #FFFFFF;
                }
            }
        }
    }
    .search-div{
        margin-top: 40px;
        width: 100%;
        padding-bottom: 0;
        margin-bottom: 26px;
        border-bottom: 2px solid #565656;
        position: relative;
        input{
            background: transparent;
            border: none;
            width: 100%;
            font-size: 28px;
            line-height: 150%;
            color: #565656;
            &:focus{
                outline: none;
            }
            &:active{
                outline: none;
            }
            &::placeholder{
                opacity: 0.5;
            }
        }
        svg{
            position: absolute;
            right: 0;
            bottom: 10px;
        }
        @include mobile{
            margin-top: 38px;
            margin-bottom: 20px;
            input{
                font-size: 16px;
                padding-left: 26px;
            }
            svg{
                position: absolute;
                right: initial;
                left: 0;
                bottom: 10px;
            }
        }
    }
    h4{
        font-weight: normal;
        font-size: 18px;
        line-height: 150%;
        color: #565656;
        margin-bottom: 18px;
        @include mobile{
            font-size: 14px;
            margin-bottom: 14px;
        }
    }
    .list-div{
        display: flex;
        height: auto;
        .alphabets{
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            margin-right: 33px;
            p{
                font-weight: normal;
                font-size: 12px;
                text-align: center;
                color: #565656;
                opacity: 0.4;
                &.cursor{
                    cursor: pointer;
                    opacity: 1;
                    &:hover{
                        text-decoration: underline;
                    }
                }
            }
            @include mobile{
                margin-right: 18px;
                p{
                    font-weight: normal;
                    font-size: 11px;
                    text-align: center;
                    color: #565656;
                }
            }
        }
        .list{
            width: 100%;
            overflow-y: scroll;
            overflow-x: hidden;
            overscroll-behavior: contain;
            height: 55vh;
            &::-webkit-scrollbar {
                width: 6px;
            }
            &::-webkit-scrollbar-track {
                background: transparent; 
            }
            &::-webkit-scrollbar-thumb {
                background: #888;
                border-radius: 10px;
            }
            &::-webkit-scrollbar-thumb:hover {
                background: #555; 
            }
            .flag-name{
                display: flex;
                align-items: center;
                cursor: pointer;
                img{
                    width: 30px;
                    height: 21px;
                    margin-right: 26px;
                    @include mobile{
                        width: 26px;
                        height: 18px;
                        margin-right: 14px;
                    }
                }
                p{
                    font-weight: bold;
                    font-size: 28px;
                    line-height: 170%;
                    color: #2C2C2C;
                    @include mobile{
                        font-size: 22px;
                    }
                }
            }
        }
    }
}