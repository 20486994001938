@import '../../Reusables.scss';

.popi{
    padding: 85px 8% 104px;
    @include mobile{
        padding: 52px 5% 90px;
    }
    .main-content{
        display: flex;
        @include mobile{
            flex-direction: column;
        }
        .text-content{
            width: 50%;
            padding-left: 100px;
            h6{
                font-family: 'Gilroy';
                font-style: normal;
                font-weight: bold;
                font-size: 14px;
                line-height: 123.2%;
                letter-spacing: 0.3em;
                color: #1629D3;
                margin-bottom: 15px;
            }
            h3{
                font-family: Gilroy;
                font-style: normal;
                font-weight: bold;
                font-size: 40px;
                line-height: 123.2%;
                color: #2C2C2C;
                margin-bottom: 39px;
            }
            p{
                font-family: Gilroy;
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 143.2%;
                color: #161616;
                margin-bottom: 39px;
            }
            button{
                padding-left: 24px;
                padding-right: 24px;
                height: 64px;
                background: #004AD9;
                font-style: normal;
                font-weight: bold;
                font-size: 16px;
                line-height: 150%;
                color: #FFFFFF;
                border: 0;
                cursor: pointer;
            }
            @include tablet{
                width: 70%;
                padding-left: 50px;
            }
            @include mobile{
                width: 100%;
                padding-left: 0;
                h6{
                    font-size: 12px;
                }
                h3{
                    font-size: 32px;
                }
                p{
                    font-size: 16px;
                    margin-right: 30px;
                }
            }
        }
        .illustration{
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            @include tablet{
                width: 30%;
                img{
                    max-width: 100%;
                    height: auto;
                }
            }
            @include mobile{
                width: 100%;
                justify-content: left;
                margin-bottom: 56px;
                img{
                    width: 75%;
                    height: auto;
                }
            }
        }
    }
}