//General styles for landing page
@import '../Reusables.scss';

.landing-page{
    .section-top{
        margin-bottom: 81px;
        h5{
            font-weight: bold;
            font-size: 14px;
            line-height: 123.2%;
            letter-spacing: 0.3em;
            color: #1629D3;
            margin-bottom: 14px;
            text-transform: uppercase;
        }
        h3{
            font-weight: bold;
            font-size: 40px;
            line-height: 123.2%;
            color: #2C2C2C;
        }
        @include mobile{
            margin-bottom: 80px;
            h5{
                font-size: 12px;
            }
            h3{
                font-size: 32px;
            }
        }
    }
}