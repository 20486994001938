@import '../../Reusables.scss';

.modal-bottom{
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 10; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(2,4,26); /* Fallback color */
    background-color: rgba(2,4,26,0.85); /* Black w/ opacity */
    -webkit-animation-name: fadeIn; /* Fade in the background */
    -webkit-animation-duration: 0.4s;
    animation-name: fadeIn;
    animation-duration: 0.4s;
    &.show{
        @include mobile{
            display: block;
        }
    }
    .main{
        background: #FFFFFF;
        position: fixed;
        bottom: 2%;
        left: 5%;
        right: 5%;
        -webkit-animation-name: slideIn;
        -webkit-animation-duration: 0.4s;
        animation-name: slideIn;
        animation-duration: 0.4s;
        .close-button{
            position: absolute;
            right: 10px;
            top: 10px;
        }
    }
}


@-webkit-keyframes slideIn {
    from {bottom: -300px; opacity: 0} 
    to {bottom: 2%; opacity: 1}
}
  
@keyframes slideIn {
    from {bottom: -300px; opacity: 0}
    to {bottom: 2%; opacity: 1}
}
  
@-webkit-keyframes fadeIn {
    from {opacity: 0} 
    to {opacity: 1}
}
  
@keyframes fadeIn {
    from {opacity: 0} 
    to {opacity: 1}
}