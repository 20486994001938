@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy'), url(./assets/fonts/Gilroy-Thin.otf);
  font-weight: 100;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy'), url(./assets/fonts/Gilroy-UltraLight.otf);
  font-weight: 200;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy'), url(./assets/fonts/Gilroy-Light.otf);
  font-weight: 300;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy'), url(./assets/fonts/Gilroy-Regular.otf);
  font-weight: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy'), url(./assets/fonts/Gilroy-Medium.otf);
  font-weight: 500;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy'), url(./assets/fonts/Gilroy-Semibold.otf);
  font-weight: 600;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy'), url(./assets/fonts/Gilroy-Bold.otf);
  font-weight: 700;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy'), url(./assets/fonts/Gilroy-Bold.otf);
  font-weight: bold;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy'), url(./assets/fonts/Gilroy-Extrabold.otf);
  font-weight: 800;
}

body {
  margin: 0;
  font-family: 'Gilroy', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,h2,h3,h4,h5,h6,p,span,button{
  margin: 0;
  font-family: 'Gilroy', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
