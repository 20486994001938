@import '../../Reusables.scss';

.countries-result{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 28px;
    @include tablet{
        grid-template-columns: 1fr 1fr;
    }
    @include mobile{
        grid-template-columns: 1fr;
    }
}