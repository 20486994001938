@import './Reusables.scss';
*{
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

*:focus, *:active{
  outline: 0;
  box-shadow: none !important;
}

html, body {
  margin: 0;
  overscroll-behavior: none;
  font-family: 'Gilroy', sans-serif !important;
}

h1,h2,h3,h4,h5,h6,p,span,button{
  margin: 0;
  font-family: 'Gilroy', sans-serif;
}
button{
  font-family: 'Gilroy' !important;
}

a{
  text-decoration: none;
}

.footer-container{
  padding: 0 8% 40px;
  background: #F6F9FC;
  margin-top: 50px;
  @include mobile{
      padding: 0 5% 40px;
  }
}